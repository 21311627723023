import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Karriereportal in Ihre Website einbinden",
  "description": "So binden Sie Stellenanzeigen auf Ihrer Website ein",
  "author": "Anna-Mai Petersen",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Variante 1: Volle Karriereseite (empfohlen)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Variante 2: Job-Liste (Widget)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Variante 3: Bewerbungsformular (Widget)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Variante 4: Karriereseiten API (Custom API)`}</p>
      </li>
    </ul>
    <h2>{`Nutzen Sie ihre eigene Domain`}</h2>
    <p>{`Teilen Sie uns an `}<a parentName="p" {...{
        "href": "mailto:support@inriva.com"
      }}>{`support@inriva.com`}</a>{` mit welche URL sie verwenden möchten. Z.B.: karriere.ihrunternehmen.de
Ihre Domain wird von uns eingerichtet und automatisch per SSL gesichert so dass die Datenübertragung verschlüsselt über HTTPS läuft.`}</p>
    <p>{`Wenn Sie kein technisches KnowHow haben, keine Sorge, kopieren Sie einfach den folgenden Text in eine E-Mail an Ihre IT:`}</p>
    <p>{`Melden Sie sich mit ihren Zugangsdaten bei Ihrem Domain-Hoster an.
Navigieren Sie zu der Seite auf der Sie den DNS Ihrer Domain verwalten können. Die Seite heißt oft "DNS Management", "Name Server Management", oder "Erweiterte Einstellungen"
Fügen Sie einen CNAME Eintrag für die zu verwendende Subdomain wie karriere.ihrunternehmen.de hinzu der auf  production.inriva.com zeigt. Speichern sie die Einstellungen.`}</p>
    <h2>{`Variante 1: Volle Karriereseite (empfohlen)`}</h2>
    <p>{`In Ihr Website-Layout (Farben, ggf. Menüstruktur, etc.) wird eine eigene Karriereseite eingebunden. Hier können Sie alle Module wie Jobliste, Kartenansicht, Arbeiten bei Baumüller, Integration von Social Media Kanälen, Mitarbeitervorteile, Teamansicht, etc. nutzen.
Richten Sie Ihre Karriereseiten-Domain ein. Verlinken Sie den Menüpunkt "Karriere/Jobs" Ihrer Website zu Ihrem inriva Karriereportal. Die volle Karriereseite kann auch mit den unten genannten Integrationen genutzt werden.`}</p>
    <h2>{`Variante 2: Jobliste (Widget)`}</h2>
    <p>{`Sie möchten nur die Stellenliste auf Ihrer Website anzeigen? Binden Sie das Karriereportal Widget in Ihre Website ein - schon haben Sie eine gutaussehende Liste von Stellen die auf Karriereportal-Anzeigen verlinkt.`}</p>
    <p>{`Auf Ihrer bestehenden Karriereseite wird eine Liste von Stellenanzeigen eingebunden (Layout anpassbar). Per Klick wird die Stellenanzeige in Ihrem CI dargestellt, verwendet allerdings den Karriereportal Standard mit aktuellster Job-Suchmaschinen-Optimierung.`}</p>
    <p>{`Die Texte & Daten der Stellenanzeige werden komplett in der Ausschreibungskarte in Dynamics NAV verwaltet.`}</p>
    <h2>{`Variante 3: Bewerbungsformular (Widget)`}</h2>
    <p>{`Sie pflegen Ihre Stellenanzeigen wie bisher im bestehenden Modul Ihrer Website. Am Ende der Stellenanzeige fügen Sie einen pro Stellenanzeige individuellen Link oder Button auf unser Bewerbungsformular hinzu.`}</p>
    <p>{`Dieses kann wahlweise in einem neuen Browser-Fenster oder in einer sogenannten „Lightbox“ direkt über der Stellenanzeige geöffnet werden und behält den Bezug zur Stellenanzeige – sowohl auf dem Smartphone als auch am PC-Bildschirm per Responsive Layout.`}</p>
    <h2>{`Variante 4: API`}</h2>
    <p>{`Sprechen Sie uns an um individuelle Integrationen über unsere Karriereseiten API zu verwenden`}</p>
    <h2>{`RSS`}</h2>
    <p>{`Ihr Karriereportal kann RSS, d.h. alle Stellenanzeigen können auch im XML-Format ausgegeben und auf anderen Seiten abonniert werden.`}</p>
    <h2>{`Gegenüberstellung der Varianten`}</h2>
    <img src="/images/settings/widgetvergleich.JPG" alt="Gegenüberstellung der Varianten" style={{
      "width": "100%"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      